ul.schedule-list {
  width: 350px;
  float: right;
  margin: 0;
  padding: 14px;
  list-style-type: none;
  text-align: center;
  & > li {
    border-radius: 2px;
    margin-bottom: 10px;
    font-size: 15px;
    &:last-child {
      margin-bottom: 0; } }
  & > li.list-section {
    display: inline-block;
    margin: 14px;
    padding: 4px 8px;
    background-color: white;
    color: black;
    font-weight: bold; }
  & > li.list-item {
    padding: 8px 16px;
    text-align: start;
    background-color: #3b3b3b;
    cursor: pointer;
    &:hover {
      background-color: #343333; } } }
