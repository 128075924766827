header.header {
  display: flex;
  background-color: #398D73;
  & > .header-center {
    min-height: 100px;
    flex-grow: 1;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 16px 0px;
    & > .header-title {
      h1, h2, h3, h4, h5, h6 {
        margin: 0; } } }
  & > .header-left, & > .header-right {
    padding: 0.8em 1em;
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between; } }
