button.button {
  background-color: #2593FC;
  color: white;
  padding: 8px 14px;
  border-radius: 8px;
  border: 0;
  cursor: pointer;
  font-weight: normal;
  font-size: 15px;
  width: 160px;
  margin: 2px; }
