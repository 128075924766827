ul.exhibition-list {
  width: 350px;
  float: left;
  margin: 0;
  padding: 14px;
  list-style-type: none;
  & > li {
    border-radius: 2px;
    margin-bottom: 10px;
    padding: 0.5em 1em;
    cursor: pointer;
    background-color: #3b3b3b;
    font-size: 15px;
    &:last-child {
      margin-bottom: 0; }
    .exhibition-title {
      font-weight: 600; }
    .exhibition-description {
      font-size: 13px;
      color: rgba(255, 255, 255, 0.8); }
    &:hover {
      background-color: #343333; } } }
